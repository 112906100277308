<template>
  <div class="archause_home">
    <div v-if="presentCampaigns.length > 0" class="banner">
      <p class="white">{{ presentCampaigns[0].Title }}</p>
      <p
        @click="scrollToCampaigns"
        class="pointer ml8 brown400"
        style="font-weight: 500"
      >
        Learn More
      </p>
    </div>
    <div class="mt0 mobile-mt0 extended wfill centered flex">
      <div
        class="arcause_hero"
        :style="{
          background:
            $mq != 'mobile'
              ? 'url(' +
                pageInfo.banner.url +
                ') top / contain no-repeat, url(../assets/arcause_final.jpg) top / contain no-repeat'
              : 'url(' +
                pageInfo.mobileBanner.url +
                ') top / contain no-repeat, url(../assets/arcause_mobile.jpg) top / contain no-repeat'
        }"
      ></div>
    </div>
    <div class="h20 white wfill m20 "></div>
    <div class="wrap extended flex section2" style="z-index: -1;">
      <div
        v-if="$mq != 'mobile'"
        style="padding: 40px 0px 40px 40px; height: 400px;"
        class="section left flex vertical"
      >
        <h1 class="fs24">What is Arcause?</h1>
        <p class="mt20 fs14" v-html="pageInfo.description"></p>
      </div>
      <!-- <div v-if="$mq != 'mobile'" class="hfill smear">
      </div>
      <div v-if="$mq != 'mobile'" class="cover"></div> -->
      <div
        class="hfill section right flex"
        style="position: relative; height: 400px;"
      >
        <h6
          class="mobile-fs16 myauto white together-text mxauto"
          style="text-align: left; line-height: 2em"
        >
          <span>Together</span> <br />
          <span class="brown600">Harnessing</span> Collaborative Strengths
          <br />
          <span class="brown600">of the</span> ACED Community <br />
          <span class="brown600">For</span> Our Cause <br />
          <span class="brown600">For Causes that</span> Align
        </h6>
        <div class="hands"></div>
      </div>
      <div
        v-if="$mq == 'mobile'"
        style="padding: 40px 80px;"
        class="section left centered flex vertical"
      >
        <h1 class="fs24">What is Arcause?</h1>
        <p class="mt20 fs14" v-html="pageInfo.description"></p>
      </div>
    </div>
    <page-header header="Arcause Campaigns" class="pb40"></page-header>
    <div class="mt12 px40 flex wrap">
      <div class="vertical flex" :class="[$mq == 'mobile' && 'wfill mt4']">
        <label for="" style="color: transparent">Search</label>
        <div style="border: 1px solid #ccc; padding: 0 12px;">
          <i class="ml8 search icon"></i>
          <input
            placeholder="Search"
            type="text"
            class="search_input"
            v-model="search"
          />
        </div>
      </div>
      <div
        id="campaigns"
        :class="[$mq == 'mobile' ? 'wfill mt4' : 'ml12']"
        class="vertical flex"
      >
        <label for="">Activity</label>
        <select v-model="filter.activity">
          <option value="" selected>All Campaigns</option>
          <option value="active">Active</option>
          <option value="past">Past</option>
          <option value="future">Future</option>
        </select>
      </div>
    </div>
    <div class="events px40 pb80 mt20">
      <div
        v-for="e in sortedCampaigns"
        :key="`${e.name}-${e.id}`"
        @click="$router.push(`arcause/${e.pageName}`)"
        class="event_card"
      >
        <img
          class="img"
          v-lazy="(e.Poster || {}).url"
          :style="{
            objectFit: 'cover',
            objectPosition: 'center'
          }"
        />
        <div class="bottom">
          <h6 class="title">
            {{ e.Title }}
          </h6>
          <div
            class="desc h60 pb40 fs12"
            v-html="e.Information.slice(0, 250)"
          ></div>
          <p class="know_more_text absolute right pt8 mlauto brown500">
            ...Know More
          </p>
        </div>
      </div>
      <div v-if="sortedCampaigns.length == 0" class="wfill px40 pb80">
        <h3
          class="center mt20"
          style="font-style: italic; text-transform: capitalize;"
        >
          <span v-if="loaded == false">Loading</span>
          <span v-else>No Campaigns</span>
        </h3>
      </div>
    </div>
    <!-- div v-if="presentCampaigns.length > 0" id="campaigns" class="extended" style="padding-top: 60px;">
      <h5 class="center">Arcause Campaigns</h5>
      <h4 class="center mb20">Current Campaigns</h4>
      <campaign v-for="c in presentCampaigns" :key="c.id" :campaign="c"></campaign>
    </div>
    <div v-if="pastCampaigns.length > 0" class="extended" style="background: #f5f5f5; padding-top: 60px;">
      <h5 class="center">Arcause Campaigns</h5>
      <h4 class="center mb20">Past Campaigns</h4>
      <campaign v-for="c in pastCampaigns" :key="c.id" :campaign="c"></campaign>
    </div -->
    <div class="wfill">
      <img
        width="100%"
        class="collaborators"
        v-lazy="`../assets/arcause_bottom_banner.png`"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import pageHeader from "../components/page-header";
export default {
  name: "Archause",
  components: { pageHeader },
  data() {
    return {
      campaigns: [],
      pageInfo: {},
      search: "",
      filter: {
        activity: ""
      }
    };
  },
  metaInfo() {
    return {
      title: this.pageInfo?.SEO?.metaTitle || "Ethos | Arcause",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            this.pageInfo?.SEO?.metaDescription ||
            "Arcause is a platform for acknowledging, enlisting and actualizing Social Responsibilities of Architects, Designers, Engineers, and others associated with the Construction field (ACED Fraternity)."
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.pageInfo?.SEO?.metaKeywords || ""
        }
      ]
    };
  },
  computed: {
    pastCampaigns: function() {
      let campaigns = [...this.campaigns];
      return campaigns
        .filter(c => {
          return this.parseDate(c.End) <= this.parseDate(moment()._d);
        })
        .sort((c1, c2) => {
          if (c1.priority != c2.priority) {
            let e1priority = c1.priority || 10;
            let e2priority = c2.priority || 10;
            return e1priority - e2priority;
          }
          return this.parseDate(c2.Start) - this.parseDate(c1.Start);
        });
    },
    presentCampaigns: function() {
      let campaigns = [...this.campaigns];
      return campaigns
        .filter(c => {
          return this.parseDate(c.End) > this.parseDate(moment()._d);
        })
        .sort((c1, c2) => {
          if (c1.priority != c2.priority) {
            let e1priority = c1.priority || 10;
            let e2priority = c2.priority || 10;
            return e1priority - e2priority;
          }
          return this.parseDate(c2.Start) - this.parseDate(c1.Start);
        });
    },
    sortedCampaigns: function() {
      let events = [...this.campaigns];
      events = events.filter(ev => !ev.hide);
      if (this.search) {
        events = events.filter(e => {
          let title = e.title || e.Title;
          let description = e.description || e.Information;
          return (
            title.toLowerCase().includes(this.search.toLowerCase()) ||
            description.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      let f = this.filter;
      switch (f.activity) {
        case "active":
          events = events.filter(e => {
            return (
              moment(e.Start).unix() <= moment().unix() &&
              moment(e.End).unix() >= moment().unix()
            );
          });
          break;
        case "past":
          events = events.filter(e => {
            return moment(e.End).unix() < moment().unix();
          });
          break;
        case "future":
          events = events.filter(e => {
            return moment(e.Start).unix() > moment().unix();
          });
      }
      return events?.sort((c1, c2) => {
        if (c1.priority != c2.priority) {
          let e1priority = c1.priority || 10;
          let e2priority = c2.priority || 10;
          return e1priority - e2priority;
        }
        return this.parseDate(c2.Start) - this.parseDate(c1.Start);
      });
    }
  },
  mounted() {
    // $(document).scrollTop(0);
    this.$req
      .get("events?type=campaign&_limit=-1")
      .then(r => {
        this.campaigns = r.data;
      })
      .catch(e => console.log(e));
    this.$req
      .get("arcause")
      .then(r => {
        this.pageInfo = r.data;
      })
      .catch(e => console.log(e));
  },
  methods: {
    scrollToCampaigns: function() {
      var elmnt = document.getElementById("campaigns");
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
    parseDate: function(d) {
      return parseInt(moment(d).format("YYYYMMDD"));
    }
  }
};
</script>

<style lang="css" scoped>
.archause_home {
  width: 100%;
  position: relative;
  padding: 0px;
  overflow: hidden;
  margin-top: 6px;
}

@media (max-width: 800px) {
  .archause_home {
    padding: 0px;
  }
}
.banner {
  padding: 8px;
  height: fit-content;
  white-space: nowrap;
  background: #252525;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.banner p {
  height: 24px;
  font-size: 16px;
  margin: auto 4px;
  font-weight: 600;
}
@media (max-width: 800px) {
  .banner p {
    font-size: 14px;
  }
  .banner {
    overflow-x: scroll;
    margin-top: 8px;
  }
}
.banner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.banner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.arcause_hero {
  height: 100vw;
  width: 100%;
  /* background: url("../assets/arcause_mobile.jpg") top / contain no-repeat; */
}
@media (min-width: 600px) {
  .arcause_hero {
    height: 37vw;
    width: 100%;
  }
}

/* .smear {
    background: url('../assets/archause_section_smear.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    position: relative;
    height: 500px;
    top: -190px;
    z-index: -1;
    left: 190px;
  } */

.section2 {
  height: 400px;
}
@media (max-width: 800px) {
  .section2 {
    height: fit-content;
  }
}
.section.left {
  width: calc(40%);
  position: relative;
  padding: 20px;
}
.section.right {
  background: url("../assets/smear.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 0px;
  width: 60%;
}

.events {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  justify-content: flex-start;
}

.hands {
  background: url("../assets/hands.svg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  bottom: 0px;
  height: 30%;
}

.event_card {
  border: 1px solid #eee;
  width: calc(220px - 20px);
  margin: 12px 20px 12px 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  height: 360px;
}

.event_card .title {
  font-size: 12px;
  margin-bottom: 12px;
}

.event_card .label {
  color: white;
  right: -8px;
  top: 20px;
  position: absolute;
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 11px;
}
.event_card .desc {
  height: 60px;
  overflow: hidden;
}
.event_card .desc * {
  font-size: 11px;
}
@media (max-width: 800px) {
  .event_card {
    width: 100%;
    margin: 12px 0px;
  }
}
.event_card .img {
  width: 100%;
  min-height: 200px;
}
.event_card .bottom {
  width: 100%;
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.event_card .know_more_text {
  font-size: 11px;
  bottom: 12px;
  right: 12px;
}

/* .cover {
    border-bottom-left-radius: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 300px 80px;
    border-color: transparent transparent #B98744 transparent;
  } */
@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    background: #b98744;
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}
@media (min-width: 800px) {
  .together-text {
    font-size: 20px;
    margin-left: auto;
    margin: auto;
  }
}
</style>
